function closePopUp() {
    console.log("in closePopUp")
    var modal = document.getElementById("myModal");
    if (modal != null) {
        console.log("Modal gefunden")
    }
    modal.style.display = "none";
    sessionStorage.setItem("closed", "true");
}

function showPopUp() {
    console.log("Wert: " + sessionStorage.getItem("closed"));
    if (sessionStorage.getItem("closed")) {
        console.log("Wurde  weggeklickt!");
        var popUp = document.getElementById("myModal");
        popUp.hidden = true;
    }
}
